// modules
import React from "react";

// CSS
import "./Loader.css"

function Loader() {
  return (
    <div className="loader">
      <span className="loader-dot"></span>
      <div className="loader-dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export default Loader;
