// import modules
import reducerActions from "./reducerActions";

export const initialState = {
  userInfo: {
    name: "",
    email: "",
  },
  clientData: null,
  reviewId: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case reducerActions.SET_CLIENT_DETAILS:
      return {
        ...state,
        clientData: {
          ...action.payload,
        },
      };
    case reducerActions.SET_REVIEW_ID:
      return {
        ...state,
        reviewId: action.payload.reviewId,
      };
    case reducerActions.SET_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...action.payload
        }
      }
    default:
      return state;
  }
};
