import React from "react";
// CSS
import "./404.css";

function NotFound() {
  return (
    <div className="notFound">
      <div className="fof">
        <h1>Error: 404</h1>
      </div>
    </div>
  );
}

export default NotFound;
