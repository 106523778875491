// modules
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useStateValue } from "../../utils/stateProvider";
import reducerActions from "../../utils/reducerActions";
import { useNavigate, useParams } from "react-router-dom";

import Modal from "react-bootstrap/Modal";

// CSS
import "./UserLogin.css";

// components
import Loader from "../Loader/Loader";
import ClientDetails from "../ClientDetails/ClientDetails";

function UserLogin() {
  // global states
  const [{ clientData }, dispatch] = useStateValue();
  // local states
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userInput, setUserInput] = useState({
    name: "",
    email: "",
  });
  const [errors, setErrors] = useState({
    name: null,
    email: null,
  });
  const [isPaused, setIsPaused] = useState(false);

  // hooks
  const navigate = useNavigate();
  const { clientId } = useParams();

  useEffect(() => {
    if (!clientData) {
      fetch("/api/Contact Details | Web/_find", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: [
            {
              "CONTACT ID MATCHING FIELD": clientId,
            },
          ],
        }),
      })
        .then((res) => res.json())
        .then((jsonRes) => {
          // here I am getting all the data and keep them into the state
          if (jsonRes.success) {
            const fieldData = jsonRes.data[0].fieldData;

            fetch(
              "/api/Contact Details | Web/records/" + jsonRes.data[0].recordId,
              {
                method: "put",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  fieldData: {
                    "QR Scan Count": Number(fieldData["QR Scan Count"]) + 1,
                  },
                }),
              }
            )
              .then((res) => {
                return res.json();
              })
              .then((jsonRes) => {
                if (jsonRes.success) {
                  if (fieldData["Account Paused"]) {
                    setIsPaused(true);
                  } else {
                    setIsPaused(false);
                  }
                  dispatch({
                    type: reducerActions.SET_CLIENT_DETAILS,
                    payload: {
                      company: fieldData.Company,
                      img: fieldData["Container_File"],
                      redirect: fieldData.Website,
                    },
                  });
                } else {
                  navigate("/404");
                }
              });
          } else {
            navigate("/404");
          }
        })
        .catch((err) => {
          // navigate("/404");
          console.log(err);
        });
    }

    // eslint-disable-next-line
  }, [clientId]);

  function handleInput(e) {
    const { name, value } = e.target;

    if (name === "name") {
      setUserInput((prev) => ({
        ...prev,
        name: value,
      }));
    } else if (name === "email") {
      setUserInput((prev) => ({
        ...prev,
        email: value,
      }));
    }
  }

  function validateField(e) {
    const { name, value } = e.target;

    switch (name) {
      case "name":
        if (value.length > 1) {
          setErrors((prev) => ({
            ...prev,
            name: 1,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            name: -1,
          }));
        }
        break;
      case "email":
        const emailRegx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (value.length === 0) {
          setErrors((prev) => ({
            ...prev,
            email: -1,
          }));
        } else if (emailRegx.test(value)) {
          setErrors((prev) => ({
            ...prev,
            email: 1,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            email: -1,
          }));
        }
        break;
      default:
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);

    if (errors.name !== 1) {
      setIsSubmitting(false);
      setErrors((prev) => ({
        ...prev,
        name: -1,
      }));
    }
    if (errors.email !== 1) {
      setIsSubmitting(false);
      setErrors((prev) => ({
        ...prev,
        email: -1,
      }));
    }
    if (
      Object.values(errors).indexOf(-1) === -1 &&
      Object.values(errors).indexOf(null) === -1
    ) {
      setIsSubmitting(true);
      dispatch({
        type: reducerActions.SET_USER_INFO,
        payload: {
          name: userInput.name,
          email: userInput.email,
        },
      });
      navigate("/app/review/" + clientId);
    }
  }

  if (!clientData) {
    return (
      <div className="position-absolute start-0 end-0 top-0 bottom-0 bg-white">
        <Loader />
      </div>
    );
  } else {
    return (
      <ClientDetails company={clientData.company} img={clientData.img}>
        <Helmet>
          <title> User - Add customer </title>
        </Helmet>

        <div className="userLogin">
          <h1 className="userLogin_title text-uppercase mb-3">
            {" "}
            ENTER YOUR NAME &amp; EMAIL BELOW{" "}
          </h1>

          <form onSubmit={handleSubmit}>
            <div className="position-relative mb-2">
              <input
                type="text"
                name="name"
                value={userInput.name}
                className={`form-control userLogin_form-input ${
                  errors.name === -1 ? "is-invalid" : ""
                }`}
                placeholder="Name"
                onChange={handleInput}
                onBlur={validateField}
                onFocus={(e) => setErrors((prev) => ({ ...prev, name: null }))}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="userLogin_form-input-icon"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="invalid-feedback">Please enter your name.</div>
            </div>
            <div className="position-relative mb-3">
              <input
                type="email"
                name="email"
                value={userInput.email}
                className={`form-control userLogin_form-input ${
                  errors.email === -1 ? "is-invalid" : ""
                }`}
                placeholder="Email"
                onChange={handleInput}
                onBlur={validateField}
                onFocus={(e) => setErrors((prev) => ({ ...prev, email: null }))}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="userLogin_form-input-icon"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
              <div className="invalid-feedback">
                Please enter your valid email.
              </div>
            </div>
            <div className="position-relative mb-3">
              <button
                type="submit"
                className={`btn submit-btn ${isSubmitting ? "pe-none" : ""}`}
              >
                {isSubmitting ? "Processing..." : "Proceed to review"}
              </button>
              {isSubmitting && (
                <svg
                  className="submit_form-loader"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <circle
                    cx="50"
                    cy="50"
                    fill="none"
                    stroke="#ffffff"
                    strokeWidth="8"
                    r="45"
                    strokeDasharray="212.05750411731105 72.68583470577035"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      repeatCount="indefinite"
                      dur="1s"
                      values="0 50 50;360 50 50"
                      keyTimes="0;1"
                    ></animateTransform>
                  </circle>
                </svg>
              )}
            </div>
          </form>
        </div>
        <AppModal show={isPaused} />
      </ClientDetails>
    );
  }
}

function AppModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-center text-danger my-4"
        >
          Account Paused
        </Modal.Title>
      </Modal.Header>
    </Modal>
  );
}

export default UserLogin;
