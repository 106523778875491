// modules
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../utils/stateProvider";
import { Helmet } from "react-helmet";
import { Alert } from "react-bootstrap";

// CSS
import "./ReviewWidget.css";

// components
import ClientDetails from "../ClientDetails/ClientDetails";

function ReviewText() {
  // global states
  const [{ clientData, reviewId }] = useStateValue();

  // component states
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState(false);

  // hooks
  const navigate = useNavigate();

  // submit handler
  function handleSubmit(e) {
    e.preventDefault();
    setAlertShow(false);
    if (feedback.length === 0) {
      setIsSubmitting(false);
      setError(true);
    } else {
      setIsSubmitting(true);
      fetch("/api/Reviews | Web/records/" + reviewId, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fieldData: {
            "Negative Review Text": feedback,
          },
        }),
      })
        .then((res) => res.json())
        .then((jsonRes) => {
          if (jsonRes.success) {
            navigate("/app/thanksPage", {
              replace: true,
            });
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          setAlertShow(true);
          console.log(err);
        });
    }
  }

  return (
    <ClientDetails company={clientData.company} img={clientData.img}>
      <Helmet>
        <title> User - Feedback </title>
      </Helmet>

      <div className="reviewText">
        <h2 className="mb-4"> LET US KNOW WHAT WE COULD HAVE DONE BETTER </h2>
        <form action="" className="mb-3" onSubmit={handleSubmit}>
          <div className="mb-2">
            <textarea
              className={`form-control reviewText_textarea ${
                error ? "is-invalid" : ""
              }`}
              placeholder="Write your feedback!"
              value={feedback}
              name="feeback"
              cols="30"
              rows="10"
              onChange={(e) => {
                setFeedback(e.target.value);
                setAlertShow(false);
              }}
              onFocus={(e) => setError(false)}
            ></textarea>
            <div className="invalid-feedback">Please give us your opinion!</div>
          </div>

          <div className="position-relative mb-3">
            <button
              type="submit"
              className={`btn submit-btn ${isSubmitting ? "pe-none" : ""}`}
            >
              {isSubmitting ? "Processing..." : "Submit"}
            </button>
            {isSubmitting && (
              <svg
                className="submit_form-loader"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  stroke="#ffffff"
                  strokeWidth="8"
                  r="45"
                  strokeDasharray="212.05750411731105 72.68583470577035"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                  ></animateTransform>
                </circle>
              </svg>
            )}
          </div>
        </form>
        {/* alert for errors */}
        <Alert
          className="rounded-0 text-center m-0"
          show={alertShow}
          variant="danger"
        >
          <p className="m-0">Something went wrong, please try again!</p>
        </Alert>
      </div>
    </ClientDetails>
  );
}

export default ReviewText;
