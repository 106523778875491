import React from "react";

function ClientDetails({ company, img, children }) {
  function generateImageURL(name) {
    return "https://filemakerhosts.com/reviews/image_data/" + name;
  }
  return (
    <div className="app_body">
      <div className="app_container">
        <div className="mb-4">
          <h1 className="company_title"> {company} </h1>
          <div className="company_imageContainer mx-auto text-center">
            <img
              className="img-fluid"
              src={generateImageURL(img)}
              alt={company}
            />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}

export default ClientDetails;
