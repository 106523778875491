// modules
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import { useStateValue } from "./utils/stateProvider";

// CSS
import "./App.css";

// import components
import ReviewWidget from "./components/ReviewWidget/ReviewWidget";
import UserLogin from "./components/UserLogin/UserLogin";
import ReviewText from "./components/ReviewWidget/ReviewText";
import NotFound from "./components/404/404";
import ThanksPage from "./components/ThanksPage/ThanksPage";

// cPanel login and SFTP
// SFTP Host: discretedigital.io
// Username: euroser1
// Password: zrzY2P2o3X5(E@
// Port: 88

function App() {
  return (
    <Router>
      <Routes>
        <Route path="app">
          <Route
            path="review/:clientId"
            element={
              <RouteWrapper>
                <ReviewWidget />
              </RouteWrapper>
            }
          />
          <Route
            path="review/:clientId/feedback"
            element={
              <RouteWrapper>
                <ReviewText />
              </RouteWrapper>
            }
          />
          <Route path=":clientId" element={<UserLogin />} />
          <Route path="thanksPage" element={<ThanksPage />} />
        </Route>
        <Route path="/404" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

function RouteWrapper({ children }) {
  const [{ clientData }] = useStateValue();
  const { clientId } = useParams();
  if (!clientData) {
    return <Navigate to={"/app/" + clientId} />;
  } else {
    return children;
  }
}

export default App;
