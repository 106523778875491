// modules
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "../../utils/stateProvider";
import reducerActions from "../../utils/reducerActions";

// CSS
import "./ReviewWidget.css";

// components
import ClientDetails from "../ClientDetails/ClientDetails";
import Loader from "../Loader/Loader";

const Review = () => {
  // global states
  const [{ clientData, userInfo }, dispatch] = useStateValue();

  // local states
  const [alertShow, setAlertShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // hooks
  const { clientId } = useParams();
  const navigate = useNavigate();

  // handle rating submission
  function handleRating(e) {
    const { value } = e.target;
    setIsLoading(true);
    setAlertShow(false);
    fetch("/api/Reviews | Web/records/", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fieldData: {
          "CONTACT ID MATCHING FIELD": clientId,
          Name: userInfo.name,
          Email: userInfo.email,
          "Review Star Count": value,
        },
      }),
    })
      .then((res) => res.json())
      .then((jsonRes) => {
        if (jsonRes.success) {
          dispatch({
            type: reducerActions.SET_REVIEW_ID,
            payload: {
              reviewId: jsonRes.data.recordId,
            },
          });
          if (value < 4) {
            navigate("/app/review/" + clientId + "/feedback", {
              replace: true,
            });
          } else {
            let URL = clientData.redirect;
            if(!/^https?:\/\//i.test(URL)){
              return window.location.assign("http://" + URL);
            }
            window.location.assign(URL);
          }
        } else {
          setIsLoading(false);
          setAlertShow(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertShow(true);
        console.log(err);
      });
  }

  if (isLoading) {
    return (
      <div className="position-absolute start-0 end-0 top-0 bottom-0 bg-white">
        <Loader />
      </div>
    );
  } else {
    return (
      <ClientDetails company={clientData.company} img={clientData.img}>
        <Helmet>
          <title> User - Review </title>
        </Helmet>

        <div className="py-3">
          <div className="widget text-center">
            <h2> HOW WOULD YOU RATE YOUR EXPERIENCE WITH US? </h2>
            <form className="mb-3">
              <div className="star-widget">
                <input
                  type="radio"
                  value={5}
                  name="rate"
                  id="rate-5"
                  onChange={handleRating}
                />
                <label htmlFor="rate-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                </label>
                <input
                  type="radio"
                  value={4}
                  name="rate"
                  id="rate-4"
                  onChange={handleRating}
                />
                <label htmlFor="rate-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                </label>
                <input
                  type="radio"
                  value={3}
                  name="rate"
                  id="rate-3"
                  onChange={handleRating}
                />
                <label htmlFor="rate-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                </label>
                <input
                  type="radio"
                  value={2}
                  name="rate"
                  id="rate-2"
                  onChange={handleRating}
                />
                <label htmlFor="rate-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                </label>
                <input
                  type="radio"
                  value={1}
                  name="rate"
                  id="rate-1"
                  onChange={handleRating}
                />
                <label htmlFor="rate-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                </label>
              </div>
            </form>

            {/* alerts */}
            <Alert
              className="rounded-0 text-center m-0"
              show={alertShow}
              variant="danger"
            >
              <p className="m-0">Something went wrong, please try again!</p>
            </Alert>
          </div>
        </div>
      </ClientDetails>
    );
  }
};

export default Review;
