// modules
import React, { useEffect, useState } from "react";

// CSS
import "./ThanksPage.css";

function ThanksPage() {
  // local states
  const [showSign, setShowSign] = useState(false);

  // hooks
  useEffect(() => {
    setShowSign(true);
  }, []);

  return (
    <div className="thanksPage">
      <div className="">
        <div className={`trigger ${showSign && "drawn"}`}></div>
        <svg
          version="1.1"
          id="tick"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 37 37"
          style={{ enableBackground: "new 0 0 37 37", width: "150px" }}
          xmlSpace="preserve"
        >
          <path
            className="circ path"
            style={{
              fill: "none",
              stroke: "#f2f2f2",
              strokeWidth: 3,
              strokeLinejoin: "round",
              strokeMiterlimit: 10,
            }}
            d="
      M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
          />
          <polyline
            className="tick path"
            style={{
              fill: "none",
              stroke: "#f2f2f2",
              strokeWidth: 3,
              strokeLinejoin: "round",
              strokeMiterlimit: 10,
            }}
            points="
      11.6,20 15.9,24.2 26.4,13.8 "
          />
        </svg>
      </div>

      <div className="thanksPage_text text-center mt-4">
        <h1> Thanks for your review! </h1>
        <p>
          {" "}
          People rely on great reviews like yours to find the best places to go.{" "}
        </p>
      </div>
    </div>
  );
}

export default ThanksPage;
